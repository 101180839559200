import jpPrefecture from 'jp-prefecture';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    AOS.init({
      duration: 800,
      anchorPlacement: 'top-top',
      once: true
    });
  },
  false
);

window.onload = function() {
  const cbxAgree = document.querySelector('#agree') || null;
  const btnConfirm = document.querySelector('.js-btn-confirm') || null;

  if (cbxAgree) {
    if (btnConfirm) {
      btnConfirm.disabled = true;

      if (cbxAgree.checked === true) {
        btnConfirm.disabled = false;
      } else {
        btnConfirm.disabled = true;
      }

      cbxAgree.addEventListener('change', function(e) {
        if (this.checked === true) {
          btnConfirm.disabled = false;
        } else {
          btnConfirm.disabled = true;
        }
      });
    }
  }
}

function linkListenerHook(el) {
  let cl = window.location.href;
  let clSubset = cl.split('#');
  let ol = clSubset[0];
  let o = window.location.origin + '/';

  el.addEventListener('click', function(e) {
    e.preventDefault();

    if (cl !== o) {
      if (ol !== o) {
        window.location.href = o;
      } else {
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    } else {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  });
}

const pageTop = document.querySelector('.page-top') || null;

if (pageTop) {
  window.onscroll = function(e) {
    if (window.pageYOffset > 200) {
      pageTop.classList.add('scrolled');
    } else {
      pageTop.classList.remove('scrolled');
    }
  }

  pageTop.addEventListener('click', function(e) {
    e.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });
}

const header = document.querySelector('.header') || null;
const headerNav = document.querySelector('.header__nav') || null;
const headerLogo = document.querySelector('.header__logo') || null;
const headerToggle = document.querySelector('.js-header-toggle') || null;
const footerLogo = document.querySelector('.footer__logo') || null;

if (headerNav) {
  headerNav.addEventListener('click', function(e) {
    if (e.target.classList.contains('header__link')) {
      e.preventDefault();

      let anc = e.target.getAttribute('href');
      const yOffset = -120; 
      let elToScroll = document.querySelector(anc) || null;

      if (elToScroll) {
        const y = elToScroll.getBoundingClientRect().top + window.pageYOffset + yOffset;

        header.classList.remove('toggled');
        document.body.classList.remove('overflow');
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      } else {
        window.location.href = window.location.origin + anc;
      }
    }
  });
}

if (headerToggle) {
  headerToggle.addEventListener('click', function(e) {
    e.preventDefault();

    header.classList.toggle('toggled');
    document.body.classList.toggle('overflow');
  });
}

if (headerLogo) {
  linkListenerHook(headerLogo);
}

if (footerLogo) {
  linkListenerHook(footerLogo);
}

const prefHook = document.querySelector('.prefHook') || null;
const zipHook = document.querySelector('.zipHook') || null;
let addr = '';

if (prefHook) {
  let prefs = jpPrefecture.getAll('pref', 'name');
  let ctr = 0;

  prefs.forEach(function(pref) {
    const opt = document.createElement('option');
    opt.textContent = pref;
    opt.value = pref;
    prefHook.appendChild(opt);
    ctr++;
  });
}

if (zipHook) {
  const btnSearchPostal = document.querySelector('.js-search-postal');

  zipHook.addEventListener('keyup', function(e) {
    addr = e.target;

    // AjaxZip3.zip2addr(addr, '', 'pref', 'addr1', 'addr2');
  });
  
  btnSearchPostal.addEventListener('click', function(e) {
    e.preventDefault();

    if (addr !== '') {
      AjaxZip3.zip2addr(addr, '', 'pref', 'addr1', 'addr2');
    }
  });
}

const costTableToggle = document.querySelector('.js-table-toggle') || null;

if (costTableToggle) {
  costTableToggle.addEventListener('click', function() {
    costTableToggle.querySelector('.cost__table-toggle-lines').classList.toggle('toggled');
    costTableToggle.nextElementSibling.classList.toggle('toggled');
  });
}

const mvHook = document.querySelector('.main-visual') || null;
const mvTracks = document.querySelectorAll('.main-visual__track') || null;
let ctr = 0;

if (mvHook) {
  const mvSlides = mvHook.querySelectorAll('.main-visual__bg') || null;
  const mvText = document.querySelector('.main-visual__text');

  setInterval(function() {
    let tmpActiveMv = mvHook.querySelector('.active');
    let tmpActiveMvTrack = document.querySelector('.main-visual__track.active');
    tmpActiveMv.classList.remove('active');
    tmpActiveMvTrack.classList.remove('active');
    ctr++;

    if (ctr === mvSlides.length) {
      ctr = 0;
      mvText.classList.toggle('white');
      mvSlides[ctr].classList.add('active');
      mvTracks[ctr].classList.add('active');
    } else {
      mvText.classList.toggle('white');
      mvSlides[ctr].classList.add('active');
      mvTracks[ctr].classList.add('active');
    }
  }, 5000);
}